import React, {useState, useEffect} from 'react'
import L from 'leaflet'
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css';

let layers;
const Mapa = ({ map, setMap, setBounds, zoomMap, setZoomMap, centerMap, setCenterMap }) => {
  
  useEffect(() => {
    if(map) {
      layers = L.featureGroup().addTo(map)
      let controle = new L.Control.Draw({
        position: 'topright',
        draw: {
          rectangle: true,
          marker: false,
          circle: false,
          circlemarker: false,
          polyline: false,
          polygon: false,
        },
        edit: {
          featureGroup: layers,
          edit: false,
          remove: true
        }
      })
      map.addControl(controle)
    }
  }, [map])

  map?.on('draw:created', function (e) {
    if(document.getElementsByClassName('leaflet-draw-section')[0].style.display === '' || document.getElementsByClassName('leaflet-draw-section')[0].style.display === 'block') {
      document.getElementsByClassName('leaflet-draw-section')[0].style.display = 'none'
      setBounds([
        [e.layer.getBounds()._southWest.lat, e.layer.getBounds()._southWest.lng],
        [e.layer.getBounds()._northEast.lat, e.layer.getBounds()._northEast.lng]
      ])

      e.layer.options.fillOpacity = 0

      setCenterMap([e.layer.getBounds().getCenter().lat, e.layer.getBounds().getCenter().lng])
      setZoomMap(6)

      layers.addLayer(e.layer)
    }
  })

  map?.on('draw:deleted', function(e) {
    if(document.getElementsByClassName('leaflet-draw-section')[0].style.display === 'none') {
      document.getElementsByClassName('leaflet-draw-section')[0].style.display = 'block'
      setBounds([[-60, -95], [15, -20]])
      setZoomMap(2)
      setCenterMap([-15.6014, -59.597])
    }
  })

  useEffect(() => {
    map?.setView(centerMap, zoomMap)
  }, [centerMap, zoomMap])

  return (
    <MapContainer
      whenCreated={setMap}
      className={'map'}
      style={{ height: '100vh', width: '100%' }}
      zoom={zoomMap}
      minZoom={4}
      center={centerMap}
      preferCanvas={'true'}
      doubleClickZoom={false}
      zoomControl={false}
    >
      <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw" />
    </MapContainer>
  )
}

export default Mapa;