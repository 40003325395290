import React, {useEffect, useState} from 'react'
import Loading from './Loading'
import L from 'leaflet'
import Fab from '@material-ui/core/Fab'
import MenuIcon from '@material-ui/icons/Menu'
import DialogContent from '@material-ui/core/DialogContent'
import Backend from '../services/Backend'
import Prevmet from '../services/Prevmet'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const Menu = ({ map, menuOpen, setMenuOpen, imagem, setImagem, imageOpacity, setLegenda, bounds, setBounds, setZoomMap, setCenterMap, ativarMeteograma, setAtivarMeteograma }) => {
  const hoje = new Date(
    Date.UTC(
        new Date().getUTCFullYear(), 
        new Date().getUTCMonth(), 
        new Date().getUTCDate(), 
        new Date().getUTCHours() - 3, 
        new Date().getUTCMinutes(), 
        new Date().getUTCSeconds())).toISOString().split('T')[0]
  const [utc, setUtc] = useState(false) // 00 = false | 12 = true \\
  const [parametros, setParametros] = useState([])
  const [parametro, setParametro] = useState(null)
  const [horas, setHoras] = useState([])
  const [hora, setHora] = useState(null)
  const [data, setData] = useState(hoje)
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [animando, setAnimando] = useState(false)
  const [contadorAnimacao, setContadorAnimacao] = useState(0)
  const [imagensAnimacao, setImagensAnimacao] = useState([])
  const [optionsBuscaMunicipio, setOptionsBuscaMunicipio] = useState([])
  const [municipioBuscado, setMunicipioBuscado] = useState('')
  const [municipio, setMunicipio] = useState(null)
  const [optionsBuscaRegiao, setOptionsBuscaRegiao] = useState([])
  const [regiaoBuscado, setRegiaoBuscado] = useState('')
  const [regiao, setRegiao] = useState(null)
  const [optionsBuscaEstado, setOptionsBuscaEstado] = useState([])
  const [EstadoBuscado, setEstadoBuscado] = useState('')
  const [estado, setEstado] = useState(null)
  const [contornoMapa, setContornoMapa] = useState(null)
  const contornoOptions = {
    fillColor: '#000',
    color: '#000',
    weight: 2,
    opacity: 1,
    fillOpacity: 0
  }

  const baixarImagensAnimacao = async () => {
    if(imagensAnimacao.length === 0) {
      if(data && parametro) {
        setLoading(true)
        let body = {
          parametro,
          data : `${data}T${utc ? '12' : '00'}:00:00`,
          bounds
        }
        await Backend.post('/animacao', body)
        .then(res => {
          if(res.data.length > 0) {
            setImagensAnimacao(res.data)
            setAnimando(true)
          } else {
            error('A animação falhou.')
          }
        }).catch(() => error('A animação falhou.'))
        setLoading(false)
      }
    } else {
      setAnimando(!animando)
    }
  }

  const removerImagemAnterior = async () => {
    map.removeLayer(imagem)
  }

  const removerContornoAnterior = async () => {
    try {
      map.removeLayer(contornoMapa)
    } catch (err) {
      console.log(err)
    }
  }

  const getParametros = async () => {
    await Backend.get('/parametros')
    .then(res => {
      if(res.data.length > 0) {
        setParametros(res.data)
        setParametro(res.data[0].value)
      }
    })
  }

  const getHoras = async () => {
    await Backend.get('/horas')
    .then(res => {
      if(res.data.length > 0) {
        setHoras(res.data)
        setHora(res.data[0].value)
      }
    })
  }

  const getRegioes = async () => {
    await Prevmet.get('/regioes')
    .then(res => setOptionsBuscaRegiao(res.data))
  }

  const getEstados = async () => {
    await Prevmet.get('/estados')
    .then(res => setOptionsBuscaEstado(res.data))
  }

  const error = msg => {
    setErrorMsg(msg)
  }

  const getImagem = async () => {
    if(data && parametro && hora && imagensAnimacao.length === 0) {
      setLoading(true)
      let elem = document.getElementById('parametros')
      let parametro_label = elem?.options[elem.selectedIndex].text;
      let body = { 
        hora,
        parametro,
        parametro_label,
        data : `${data}T${utc ? '12' : '00'}:00:00`,
        bounds
      }
      await Backend.post('/imagem/search', body)
      .then(async (res) => {
        if(res.data.imagem.includes('data:text/xml')) {
          error('Imagem não encontrada.')
        } else {
          error('')
          if(imagem) {
            await removerImagemAnterior()
          }
          const img = L.imageOverlay(res.data.imagem, bounds, { opacity: imageOpacity })
          setImagem(img)
          setLegenda(body)
        }
      }).catch(() => error('Imagem não encontrada.'))
      setLoading(false)
    }
  }

  const handleHoraAtiva = async (id) => {
    if(!animando) {
      if(imagensAnimacao.length > 0) {
        // Colocar a animação para continuar de onde a pessoa clicou
        let _id = id / 3
        console.log(_id)
        if (imagensAnimacao[_id] !== undefined) {
          await removerImagemAnterior()
          setContadorAnimacao(_id)
          setImagem(L.imageOverlay(
            imagensAnimacao[_id].imagem,
            imagensAnimacao[_id].bounds,
            { opacity: imageOpacity }))
        }
      }

      setHora(id)
      let className = 'btn-ativo'

      let antigo = document.getElementsByClassName(className)
      if(antigo.length > 0) {
        antigo[0].classList.remove(className)
      }

      let novo = document.getElementById(id)
      novo.classList.add(className)
    }
  }

  const getBoundsFromGeocode = async (geocode) => {
    await Prevmet.get(`/buscar/cidade/${geocode}`)
    .then(res => {
      let geoJsonLayer = L.geoJson(JSON.parse(res.data[0].poligono), contornoOptions)
      
      removerImagemAnterior()
      removerContornoAnterior()
      setContornoMapa(geoJsonLayer)

      setZoomMap(10)
      setCenterMap([geoJsonLayer.getBounds().getCenter().lat, geoJsonLayer.getBounds().getCenter().lng])
      setBounds([
        [geoJsonLayer.getBounds()._southWest.lat, geoJsonLayer.getBounds()._southWest.lng],
        [geoJsonLayer.getBounds()._northEast.lat, geoJsonLayer.getBounds()._northEast.lng]
      ])
    })
  }

  const resetarMapa = () => {
    setAnimando(false)
    setContadorAnimacao(0)
    setImagensAnimacao([])
    setBounds([[-60, -95], [15, -20]])
    setZoomMap(2)
    setCenterMap([-15.6014, -59.597])
  }

  useEffect(() => {
    if(parametro && hora && !animando) {
      getImagem()
    }
  }, [parametros, parametro, utc, data, bounds, hora]) // eslint-disable-line

  useEffect(() => {
    setAnimando(false)
    setContadorAnimacao(0)
    setImagensAnimacao([])
  }, [data, parametro, utc, bounds, regiao, estado, municipio])

  useEffect(() => {
    let intervalId;

    if(animando) {
      intervalId = setInterval(async () => {
        await removerImagemAnterior()
        let elem = document.getElementById('parametros')
        let parametro_label = elem?.options[elem.selectedIndex].text;

        setImagem(L.imageOverlay(imagensAnimacao[contadorAnimacao].imagem, bounds, { opacity: imageOpacity }))
        setHora(imagensAnimacao[contadorAnimacao].hora)
        setLegenda({
          hora: imagensAnimacao[contadorAnimacao].hora,
          parametro,
          parametro_label,
          data : `${data}T${utc ? '12' : '00'}:00:00`,
        })
        contadorAnimacao === imagensAnimacao.length - 1 ? setContadorAnimacao(0) : setContadorAnimacao(contadorAnimacao => contadorAnimacao + 1)
      }, 500)
    }

    return () => clearInterval(intervalId)
  }, [animando, contadorAnimacao])

  useEffect(() => {
    if(document.getElementsByClassName('leaflet-control-container')[0] !== undefined) {
      if(animando || loading) {
        document.getElementsByClassName('leaflet-control-container')[0].style.display = 'none'
      } else {
        document.getElementsByClassName('leaflet-control-container')[0].style.display = 'block'
      }
    }
  }, [animando, loading, map])

  useEffect(() => {
    getParametros()
    getHoras()
    getRegioes()
    getEstados()
  }, [])

  useEffect(() => {
    if(municipioBuscado && municipioBuscado.length % 2 === 0) {
      Prevmet.get(`/autocomplete/${municipioBuscado}`)
      .then(res => setOptionsBuscaMunicipio(res.data))
    }
  }, [municipioBuscado])

  useEffect(() => {
    if(municipio) {
      getBoundsFromGeocode(municipio.geocode)
    }
  }, [municipio])

  useEffect(() => {
    if(regiao) {
      let geoJsonLayer = L.geoJson(JSON.parse(regiao.poligono), contornoOptions)

      removerImagemAnterior()
      removerContornoAnterior()
      setContornoMapa(geoJsonLayer)

      setZoomMap(5)
      setCenterMap([regiao.centroide.split(',')[1], regiao.centroide.split(',')[0]])
      setBounds([
        [geoJsonLayer.getBounds()._southWest.lat, geoJsonLayer.getBounds()._southWest.lng],
        [geoJsonLayer.getBounds()._northEast.lat, geoJsonLayer.getBounds()._northEast.lng]
      ])
    }

  }, [regiao])

  useEffect(() => {
    if(estado) {
      let geoJsonLayer = L.geoJson(JSON.parse(estado.poligono), contornoOptions)

      removerImagemAnterior()
      removerContornoAnterior()
      setContornoMapa(geoJsonLayer)

      setZoomMap(6)
      setCenterMap([estado.centroide.split(',')[1], estado.centroide.split(',')[0]])
      setBounds([
        [geoJsonLayer.getBounds()._southWest.lat, geoJsonLayer.getBounds()._southWest.lng],
        [geoJsonLayer.getBounds()._northEast.lat, geoJsonLayer.getBounds()._northEast.lng]
      ])
    }

  }, [estado])

  useEffect(() => {
    if(contornoMapa) {
      contornoMapa.addTo(map)
    }
  }, [contornoMapa])

  return (
    <div className="menu">
      <Fab aria-label="menu" onClick={() => setMenuOpen(!menuOpen)}><MenuIcon /></Fab>
      <div className="menu-box" style={{ display : menuOpen ?  'flex' : 'none' }}>
      {loading ? <Loading /> : 
        <>
          <DialogContent>
            <div className="menu-container">
            <div className="opcoes-data" style={{ display : animando ?  'none' : 'block' }}>
                <input 
                  id="data"
                  type="date" 
                  value={data} 
                  onChange={e => setData(e.target.value)} 
                  max={hoje}
                  min={new Date(new Date(hoje).setDate(new Date(hoje).getDate() - 20)).toISOString().split('T')[0]}
                />
              </div>

              <div className="opcoes-utc" style={{ display : animando ?  'none' : 'block' }}>
                <span style={{ fontSize: 20, fontWeight: 500, marginRight: 10 }}>
                  00:00
                </span>

                <label className="switch">
                  <input id="utc" type="checkbox" checked={utc} onChange={() => { setUtc(!utc) }}/>
                  <span className="slider round"></span>
                </label>

                <span style={{ fontSize: 20, fontWeight: 500, marginLeft: 10 }}>
                  12:00
                </span>
              </div>

              {parametros.length > 0 ? 
                <div className="opcoes-parametros" style={{ display : animando ?  'none' : 'block' }}>
                  <select onChange={e => setParametro(e.target.value)} value={parametro} id="parametros">
                    {parametros.map(parametro => { return <option key={parametro.value} value={parametro.value}>{parametro.label}</option>})}
                  </select><br /><br />
                </div>
              : null}

              {horas.length > 0 ? 
                <div className="opcoes-horas">
                  {horas.map(h => { 
                    if(parametro === 'PREC24AS' && h.value < 24) {
                      if(parseInt(hora) < 24) {
                        setHora(24)
                      }
                      return undefined
                    }

                    if(parametro === 'PRECACUMAS' && h.value < 3) {
                      if(parseInt(hora) < 3) {
                        setHora(3)
                      }
                      return undefined
                    }

                    if(parametro === 'PRECV10AS' && h.value < 3) {
                      if(parseInt(hora) < 3) {
                        setHora(3)
                      }
                      return undefined
                    }
                    return (
                      <button 
                        id={h.value} 
                        key={h.value} 
                        value={h.value} 
                        className={h.value === parseInt(hora) ? "btn-hora btn-ativo" : "btn-hora"}
                        onClick={e => handleHoraAtiva(e.target.id)}
                      >
                        {h.label}
                      </button>
                    )}
                  )}
                </div>
              : null}

              <div className="search-regiao-container" style={{ display : animando ?  'none' : 'block' }}>
                <Autocomplete
                  id="buscarRegiao"
                  options={optionsBuscaRegiao}
                  getOptionLabel={option => option.nome}
                  onChange={(event, newValue) => setRegiao(newValue)}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Buscar Região"
                    onChange={(event) => setRegiaoBuscado(event.target.value)}
                  />}
                />
              </div>

              <div className="search-estado-container" style={{ display : animando ?  'none' : 'block' }}>
                <Autocomplete
                  id="buscarEstado"
                  options={optionsBuscaEstado}
                  getOptionLabel={option => option.nome}
                  onChange={(event, newValue) => setEstado(newValue)}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Buscar Estado"
                    onChange={(event) => setEstadoBuscado(event.target.value)}
                  />}
                />
              </div>

              <div className="search-municipio-container" style={{ display : animando ?  'none' : 'block' }}>
                <Autocomplete
                  id="buscarMunicipio"
                  options={optionsBuscaMunicipio}
                  getOptionLabel={option => option.label}
                  onChange={(event, newValue) => setMunicipio(newValue)}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Buscar Munícipio"
                    onChange={(event) => setMunicipioBuscado(event.target.value)}
                  />}
                />
              </div>

              <div className="opcoes-meteograma" style={{ display : animando ?  'none' : 'block' }}>
                <span style={{ fontSize: 20, fontWeight: 500, marginRight: 10 }}>
                  Desativar Meteograma
                </span>

                <label className="switch">
                  <input id="meteograma" type="checkbox" checked={ativarMeteograma} onChange={() => { setAtivarMeteograma(!ativarMeteograma) }}/>
                  <span className="slider round"></span>
                </label>

                <span style={{ fontSize: 20, fontWeight: 500, marginLeft: 10 }}>
                  Ativar Meteograma
                </span>
              </div>
  
              <div className="animar-container">
                <span className="btn-animar" onClick={baixarImagensAnimacao}>{animando ? 'Parar Animação' : 'Animar Imagens'}</span>
              </div>

              <div class="reset-container" style={{ display : animando ?  'none' : 'flex' }}>
                <span className="btn-reset" onClick={resetarMapa}>Resetar</span>
              </div>
              <span className="error-msg">{errorMsg}</span>
            </div>
          </DialogContent>
        </> 
      }
      </div>
    </div>
  )
}

export default Menu;