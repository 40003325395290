import React, {useEffect, useState} from 'react'
import Mapa from '../components/Mapa'
import Menu from '../components/Menu'
import Legenda from '../components/Legenda'
import Meteograma from '../components/Meteograma'
import 'leaflet/dist/leaflet.css'
import './Style.css'
import './Mobile.css'

const Index = () => {
  const [map, setMap] = useState(null)
  const [imagem, setImagem] = useState(null)
  const [menuOpen, setMenuOpen] = useState(true)
  const [legenda, setLegenda] = useState('')
  const [imageOpacity, setImageOpacity] = useState(0.6) // eslint-disable-line
  const [bounds, setBounds] = useState([[-60, -95], [15, -20]])
  const [zoomMap, setZoomMap] = useState(3)
  const [centerMap, setCenterMap] = useState([-15.6014, -59.597])
  const [ativarMeteograma, setAtivarMeteograma] = useState(false)

  useEffect(() => {
    if(imagem) {
      imagem.setOpacity(imageOpacity)
    }
  }, [imageOpacity, imagem])

  useEffect(() => {
    if(legenda && imagem && map) {
      imagem.addTo(map)
    }
  }, [imagem, map, legenda])

  return (
    <div className="container">
      <Menu 
        map={map}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen} 
        imagem={imagem}
        setImagem={setImagem}
        imageOpacity={imageOpacity}
        setLegenda={setLegenda}
        bounds={bounds}
        setBounds={setBounds}
        setZoomMap={setZoomMap}
        setCenterMap={setCenterMap}
        ativarMeteograma={ativarMeteograma}
        setAtivarMeteograma={setAtivarMeteograma}
      />
      <Mapa 
        map={map}
        setMap={setMap} 
        setBounds={setBounds}
        zoomMap={zoomMap}
        setZoomMap={setZoomMap}
        centerMap={centerMap}
        setCenterMap={setCenterMap}
      />
      <Legenda legenda={legenda} />
      
      <Meteograma 
        map={map} 
        ativarMeteograma={ativarMeteograma}
      />
    </div>
  )
}

export default Index;
