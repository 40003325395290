import React, {useState} from 'react'
import ApiMeteograma from '../services/Meteograma'
import LoadingGif from '../assets/Loading.gif'

const Meteograma = ({ map, ativarMeteograma }) => {
  const [modalOpen, setModalOpen] = useState(false)
  if(ativarMeteograma) {
    map?.on('click', async function(e) {
      try {
        const modal =  document.getElementById('meteograma-modal-body')
        const { lat, lng } = e.latlng
        const data = document.getElementById('data')
        const utc = document.getElementById('utc')

        modal.innerHTML = `<br /><img src="${LoadingGif}" class="loading-img" /><br /><br /><span class="meteograma-msg">Buscando meteograma!</span><br />`
        setModalOpen(true)

        await ApiMeteograma.get(`/agricultor/${lat}/${lng}/${data?.value}/${utc?.checked ? '12' : '00'}`)
        .then(res => {
          if(res.data.base64 && res.data.base64.includes('data:image/png;base64')) {
            modal.innerHTML = `<img src="${res.data.base64}" width="50%" height="auto" />`
          } else {
            modal.innerHTML = '<br /><br /><span class="meteograma-msg">Erro ao buscar meteograma!</span><br /><br />'
          }
        }).catch(() => modal.innerHTML = '<br /><br /><span class="meteograma-msg">Erro ao buscar meteograma!</span><br /><br />')
      } catch { }
    })

    return (
      <div id="meteograma-modal" className="modal" style={{ display : modalOpen ? 'block' : 'none' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h2>Meteograma</h2>
            <span className="close close-modal" onClick={() => setModalOpen(!modalOpen)}>&times;</span>
          </div>
          <div className="modal-body" id="meteograma-modal-body"></div>
        </div>
      </div> 
    )
  }

  return <></>
}

export default Meteograma;