import React from 'react'
import LoadingGif from '../assets/Loading.gif'

const Loading = () => {
  return (
    <div className="container">
      <div className="loading-container">
        <img src={LoadingGif} alt="Loading" className="loading-img" />
        <span className="loading-msg">Carregando Imagem...</span>
      </div>
    </div>
  )
}

export default Loading;