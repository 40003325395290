import React from 'react'

const Legenda = ({ legenda }) => {

  if(legenda) {
    return (
      <div className="container-legenda">
        <span>{legenda.parametro_label}</span><br />
        <span>{legenda.data.split('T')[0].split('-').reverse().join('/')} - {legenda.data.split('T')[1].split(':')[0]} UTC</span><br />
        <span>+{('000' + legenda.hora).slice(-3)}</span>
      </div>
    )
  }

  return <></>
}

export default Legenda;